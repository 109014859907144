import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';

import Layout from '@/components/Layout';
import TestimonialItem from '@/components/Testimonial';
import FAQ from '@/components/FAQ/FAQ';
import Project from '@/components/Project/Project';

import PricingTable from '@/components/PricingTable/Table';

class HomePageTemplate extends React.Component {
  render() {
    const data = this.props.data;
    const projects = this.props.projects;
    const testimonials = this.props.testimonials;

    return (
      <>
        <section className='hero relative py-16 min-h-900 flex items-center'>
          <Img
            fluid={data.hero.image.childImageSharp.fluid}
            alt={data.hero.heading}
            className='background hidden md:block'
          />
          <Img
            fluid={data.hero.mobileImage.childImageSharp.fluid}
            alt={data.hero.heading}
            className='background md:hidden'
          />
          <div className='container-lg relative'>
            <div className='hero__content'>
              <h1 className='text-h3 sm:text-h1 text-white span-yellow'>
                {ReactHtmlParser(data.hero.heading)}
              </h1>
            </div>
          </div>
        </section>
        <section className='py-20'>
          <div className='container-lg overflow-x-hidden'>
            <div className='flex flex-wrap justify-between items-end park-container'>
              {data.perks &&
                data.perks.map((perk, i) => {
                  return (
                    <article className='perk'>
                      <div className='pill'></div>
                      <h1 className='text-h4 sm:text-h2 mt-3 mb-4'>
                        {perk.title}
                      </h1>
                      <p class='text-lg'>{perk.description}</p>
                      <Img
                        fluid={perk.image.childImageSharp.fluid}
                        alt=''
                        className='relative w-full'
                      />
                    </article>
                  );
                })}
            </div>
          </div>
        </section>
        <section className='my-10 relative'>
          <div className='wave wave--top text-yellow-light'>
            <ReactSVG src='/img/wave.svg' className='fill-current' />
          </div>
          <div className='bg-yellow-light waved'>
            <div className='container-lg'>
              <div className='flex flex-wrap space-x-6 justify-between items-center'>
                <div className='w-full md:w-5/12'>
                  <h1 className='text-h3 sm:text-h1'>{data.about.title}</h1>
                  <ReactMarkdown
                    className='larger-text mt-5'
                    source={data.about.description}
                  />
                  <div className='mb-8 md:mb-0 mt-6 mx-auto md:mx-0 flex justify-center md:justify-start'>
                    <btn
                      onClick={() => scrollTo('#contact')}
                      className='btn-red '
                    >
                      Hire the truck
                    </btn>
                  </div>
                </div>
                <div className='w-full md:w-5/12'>
                  <Img
                    fluid={data.about.image.childImageSharp.fluid}
                    alt=''
                    className='relative z-10 w-full'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='wave text-yellow-light'>
            <ReactSVG src='/img/wave.svg' />
          </div>
        </section>

        <section className='py-24'>
          <div className='container-lg overflow-x-hidden'>
            <div className='max-w-4xl mx-auto text-center mb-5'>
              <h1 className='text-h3 sm:text-h1 span-purple'>
                {ReactHtmlParser(data.projects.title)}
              </h1>
            </div>
            <ReactMarkdown
              className='max-w-xl mx-auto text-center'
              source={data.projects.description}
            />

            <div className='projects'>
              {data.projects.projects &&
                data.projects.projects.map((item) => {
                  let project = projects.find(
                    (el) => el.node.frontmatter.title === item,
                  );
                  return <Project key={project.node.id} project={project} />;
                })}
            </div>
          </div>
        </section>

        <section className='my-10 relative'>
          <div className='wave wave--top wave--reverse text-green-lighter'>
            <ReactSVG src='/img/wave.svg' />
          </div>
          <div className='bg-green-lighter waved over-table'>
            <div className='container-lg'>
              <div className=' mx-auto mb-12 flex justify-between text-left'>
                <h1 className='text-h4 sm:text-h2'>{data.rates.title}</h1>
                <button
                  onClick={() => scrollTo('#contact')}
                  className='btn-border'
                >
                  Request a quote
                </button>
              </div>
            </div>
          </div>
          <div className='wave wave--reverse text-green-lighter'>
            <ReactSVG src='/img/wave.svg' />
          </div>
          <div className='container-lg table relative z-10'>
            <PricingTable pricing={data.rates.pricing}></PricingTable>
          </div>
        </section>

        <section className='py-16 relative'>
          <div className='container-lg'>
            <div className='gallery-grid'>
              {data.offer.gallery &&
                data.offer.gallery.map((image) => {
                  return (
                    <Img
                      fluid={image.image.childImageSharp.fluid}
                      alt=''
                      className='relative z-10 w-full'
                    />
                  );
                })}
            </div>
          </div>
          <div className='container-lg md:flex items-center justify-between'>
            <div className='max-w-3xl'>
              <h1 className='title-3 mb-4'>{data.offer.title}</h1>
              <ReactMarkdown
                className='larger-text'
                source={data.offer.content}
              />
            </div>
            <div className='md:max-w-xl md:ml-1'>
              <div className='p-8 md:p-12 md:pb-2 bg-purple-light'>
                <h2 className='text-h4 mb-2 text-purple'>
                  {data.offer.box.title}
                </h2>
                <ReactMarkdown
                  className='larger-text'
                  source={data.offer.box.content}
                />
                <button
                  onClick={() => scrollTo('#contact')}
                  className='btn-red'
                >
                  {data.offer.box.buttontext}
                </button>
              </div>
              <div className='wave text-purple-light'>
                <ReactSVG src='/img/wave.svg' />
              </div>
            </div>
          </div>
        </section>

        <section className='py-20'>
          <div className='container-lg'>
            <div className='max-w-4xl mx-auto text-center mb-10'>
              <h1 className='text-h3 sm:text-h1'>{data.testimonials.title}</h1>
            </div>
            <div className='flex flex-wrap'>
              {data.testimonials.testimonials &&
                data.testimonials.testimonials.map((item) => {
                  let testimonial = testimonials.find(
                    (el) => el.node.frontmatter.title === item,
                  );
                  return (
                    <div
                      className='w-full lg:w-1/2 p-3'
                      key={testimonial.node.id}
                    >
                      <TestimonialItem testimonial={testimonial} />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section className='py-20' id='contact'>
          <div className='container-lg'>
            <div className='max-w-4xl mx-auto text-center mb-3'>
              <h1 className='text-h3 sm:text-h1'>{data.contact.title}</h1>
            </div>
            <ReactMarkdown
              className='max-w-3xl mx-auto text-center'
              source={data.contact.description}
            />
            <div
              className='max-w-lg mx-auto mt-12 shadow p-6 rounded-md border-2 border-gray-lighter'
              id='#contact'
            >
              <iframe
                className='clickup-embed clickup-dynamic-height'
                src='https://forms.clickup.com/6965282/f/6mj12-94442/EDANFOGONTG0BUHZNM'
                onwheel=''
                width='100%'
                height='100%'
                style={{ height: '1400px' }}
              ></iframe>
              <script
                async
                src='https://app-cdn.clickup.com/assets/js/forms-embed/v1.js'
              ></script>
            </div>
          </div>
        </section>

        <section className='mt-10 relative py-1'>
          <div className='wave wave--top wave--reverse text-purple-light'>
            <ReactSVG src='/img/wave.svg' />
          </div>
          <div className='bg-purple-light relative'>
            <div className='container-lg waved'>
              <div className='max-w-4xl mx-auto text-center mb-10'>
                <h1 className='text-h3 sm:text-h1'>{data.faq.title}</h1>
              </div>
              <div className='px-5 max-w-5xl mx-auto'>
                <FAQ content={data.faq.faqs} />
              </div>
            </div>
          </div>
          <div className='wave text-purple-light wave--reverse'>
            <ReactSVG src='/img/wave.svg' />
          </div>
        </section>
      </>
    );
  }
}

HomePageTemplate.propTypes = {
  data: PropTypes.object,
};

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: projects } = data.projects;
  const { edges: testimonials } = data.testimonials;

  return (
    <Layout>
      <HomePageTemplate
        data={frontmatter}
        projects={projects}
        testimonials={testimonials}
      />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        title
        hero {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mobileImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        perks {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        about {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        projects {
          title
          description
          projects
        }
        rates {
          title
          pricing {
            title
            subtitle
            items {
              icon {
                publicURL
              }
              content {
                name
                price
              }
            }
          }
        }

        offer {
          gallery {
            image {
              childImageSharp {
                fluid(maxWidth: 1100, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          title
          content
          box {
            title
            content
            buttontext
            buttonurl
          }
        }
        testimonials {
          title
          testimonials
        }
        contact {
          title
          description
        }
        faq {
          title
          faqs {
            question
            answer
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "project" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 720, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            details {
              partner
              purpose
              when
              roles
              attendance
            }
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "testimonial" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            author
            content
          }
        }
      }
    }
  }
`;
