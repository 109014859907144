import React from 'react'
import ReactSVG from 'react-svg'
import ReactMarkdown from 'react-markdown'

const TestimonialItem = class extends React.Component {
  render() {
    const testimonial = this.props.testimonial.node.frontmatter
    return (
      <div className='testimonial'>
        <div className='testimonial__inner'>
          <ReactSVG
            src='/img/quote.svg'
            className='testimonial__quote'></ReactSVG>
          <div className='testimonial__content'>
            <ReactMarkdown source={testimonial.content}></ReactMarkdown>
          </div>
          <div className='testimonial__author'>{testimonial.author}</div>
        </div>
        <div className='wave text-yellow-light'>
          <ReactSVG src='/img/wave.svg' />
        </div>
      </div>
    )
  }
}

export default TestimonialItem
