import React, { Component } from 'react'
import Img from 'gatsby-image'
import Attribute from '@/components/Project/Attribute'

export default class Project extends Component {
  render() {
    const project = this.props.project.node.frontmatter
    const details = project.details
    return (
      <div className='project'>
        <Img fluid={project.thumbnail.childImageSharp.fluid} />
        <h1 className='title-4 mb-3 md:mb-10 mt-5'>{project.title}</h1>
        {project &&
          Object.keys(details).map((key, i) => (
            <Attribute key={i} name={key} value={details[key]}></Attribute>
          ))}
      </div>
    )
  }
}
