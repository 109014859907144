import React, { Component } from 'react'
import Question from '@/components/FAQ/Question'

export default class FAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentActiveQuestion: 0,
    }

    this.handleQuestionChange = this.handleQuestionChange.bind(this)
  }

  handleQuestionChange(id) {
    this.setState((prevState) => {
      return {
        currentActiveQuestion: prevState.currentActiveQuestion === id ? 0 : id,
      }
    })
  }
  render() {
    const questions = this.props.content
    return (
      <div className='faq-wrapper'>
        {questions.map((item, i) => {
          return (
            <Question
              key={i}
              id={i + 1}
              item={item}
              active={i + 1 == this.state.currentActiveQuestion}
              toggleQuestion={this.handleQuestionChange}
            />
          )
        })}
      </div>
    )
  }
}
