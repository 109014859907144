import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import SlideToggle from 'react-slide-toggle'
import ReactMarkdown from 'react-markdown'

export default class Question extends Component {
  render() {
    const faqClass = this.props.active ? 'faq faq--active' : 'faq'
    const isCollapsed = !this.props.active
    return (
      <SlideToggle
        collapsed
        collapseEvent={this.props.active == false && Date.now()}
        expandEvent={this.props.active == true && Date.now()}>
        {({ setCollapsibleElement }) => (
          <article className={faqClass}>
            <div
              className='faq__header'
              onClick={() => this.props.toggleQuestion(this.props.id)}>
              <h1 className='faq__question'>{this.props.item.question}</h1>
              <div className='faq-icon'>
                <ReactSVG
                  src='/img/plus.svg'
                  className='faq-icon__item faq-icon__item--closed'></ReactSVG>
                <ReactSVG
                  src='/img/minus.svg'
                  className='faq-icon__item faq-icon__item--open'></ReactSVG>
              </div>
            </div>
            <div className='faq__answer' ref={setCollapsibleElement}>
              <ReactMarkdown
                className='faq__answer__inner'
                linkTarget='_blank'
                source={this.props.item.answer}></ReactMarkdown>
            </div>
          </article>
        )}
      </SlideToggle>
    )
  }
}
