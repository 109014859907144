import React, { Component } from 'react'
import ReactSVG from 'react-svg'

export default class PricingTable extends Component {
  render() {
    return (
      <div className='pricing'>
        {this.props.pricing && this.props.pricing.map((item, i) => {
          return (
            <div className="pricing-card" key={i}>
              <div className="pricing-card__header">
                <div className="text-h5 md:text-h4 font-extrabold">{item.title}</div>
                {item.subtitle && (<div>{item.subtitle}</div>)}
              </div>
              <div className="pricing-card__body">
                {item.items.map((perk, i) => {
                  return (
                    <div className="pricing-item" key={i}>
                      <div className="pricing-item__icon">
                        {perk.icon && (<ReactSVG src={perk.icon.publicURL}></ReactSVG>)}
                      </div>
                      <div className="pricing-item__content text-sm">
                        {perk.content.map((item, i) => {
                          return (
                            <div className="pricing-position">
                              <div className="pricing-position__descritpion">
                                {item.name}
                              </div>
                              {item.price && (
                                <div className="pricing-position__price">
                                  {item.price}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}


      </div>
    )
  }
}
