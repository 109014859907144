import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

export default class Attribute extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  render() {
    return (
      <div className='attribute'>
        <div className='attribute__name'>
          {this.capitalizeFirstLetter(this.props.name)}
        </div>
        <ReactMarkdown className='attribute__value' source={this.props.value} />
      </div>
    )
  }
}
